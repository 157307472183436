import Cmp from './SearchResults.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentsearchResultsQuery {
    allWordpressWpTypes {
      edges {
        node {
          name
          slug
          rest_base
        }
      }
    }
  }
`

export default Cmp
