import React, { useState, useEffect } from 'react'
import fetch from 'isomorphic-fetch'
import stripHtml from 'string-strip-html'
import is from 'date-fns/locale/is'
import format from 'date-fns/format'
import Url from 'url-parse'
import MainLayout from '../../components/MainLayout'
import TopHero from '../../components/Sections/TopHero'
import SearchInput from '../../components/SearchInput/SearchInput'
import Link from '../../components/Link'
import { TRANSLATIONS } from '../../misc/translations'

const SearchResults = ({ data, pageContext: { language = 'is' } }) => {
  const [searchResults, setsearchResults] = useState([])

  const topHeroContent = {
    image: false,
    title: TRANSLATIONS[language].searchPageTitle,
    paragraph: ''
  }

  useEffect(() => {
    onSearchSubmit(getInitalSearchString())
  }, [])

  const getInitalSearchString = () => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href)
      return url.searchParams.get('q')
    }
    return ''
  }

  const handleSearchSubmit = searchString => {
    onSearchSubmit(searchString)
  }

  const prepareResponse = responses => {
    const preparedResponse = responses.reduce((acc, cur, i) => {
      acc[cur.type] = cur
      return acc
    }, {})

    setsearchResults(preparedResponse)
  }

  const removeUnwantedContentTypes = () => {
    const allContentTypes = data.allWordpressWpTypes.edges
    const wantedTypes = ['pages']

    return allContentTypes.filter((type, i) => {
      return wantedTypes.find(wantedType => {
        return wantedType === type.node.rest_base
      })
    })
  }

  const contentTypes = removeUnwantedContentTypes()

  const onSearchSubmit = searchString => {
    const searchStr = searchString !== null ? searchString : ''
    const searchPromise = (type, name) => {
      return fetch('/api/wp-json/wp/v2/' + type + '?search=' + searchStr)
        .then(response => response.json())
        .then(data => ({
          type: type,
          name: name,
          data: data,
          count: data.length
        }))
    }
    Promise.all(
      contentTypes.map((type, i) => {
        return searchPromise(type.node.rest_base, type.node.name)
      })
    ).then(responses => {
      prepareResponse(responses)
    })
  }

  return (
    <MainLayout topSpacing>
      <TopHero {...topHeroContent} />
      <div className='container'>
        <div className='row vspace-l-top'>
          <div className='col-md-12'>
            <h2 className='headline-thin'>
              {TRANSLATIONS[language].searchPageInputTitle}
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 z-0'>
            <SearchInput
              initialValue={getInitalSearchString()}
              onSubmit={handleSearchSubmit}
              placeholder={TRANSLATIONS[language].searchInputPlaceholder}
            />
          </div>
        </div>
        <div className='row search-results'>
          <ul className='col-md-11 col-md-push-1'>
            {searchResults.pages &&
              searchResults.pages.count > 0 &&
              searchResults.pages.data.map((item, i) => {
                const contentSections =
                  item.acf && item.acf.sections
                    ? item.acf.sections.filter(
                        item => item.acf_fc_layout === 'content',
                        []
                      )
                    : []
                const content =
                  contentSections.length > 0 &&
                  contentSections[0].content &&
                  stripHtml(contentSections[0].content)
                const publishDateFormatted = format(
                  new Date(item.date),
                  'dd/MM/yyyy - hh:mm',
                  { locale: is }
                )
                const extraContent = () =>
                  content && content.length > 300 ? '...' : ''
                const itemUrl = new Url(item.link).pathname
                return (
                  <li className='search-result' key={i}>
                    <h3>
                      <span className='ss-calendar' />
                      {publishDateFormatted}
                    </h3>
                    <Link to={itemUrl}>
                      <h1>{item.title.rendered}</h1>
                    </Link>
                    {content !== false && (
                      <p className='search-snippet'>
                        {content.slice(0, 300)}
                        {extraContent()}
                      </p>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </MainLayout>
  )
}

export default SearchResults
